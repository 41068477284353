import React, { FC, useRef, useState } from 'react'
import { Swiper } from 'swiper/react'
import styled, { css } from 'styled-components'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/lazy/lazy.min.css'
import { Box, Flex } from '@/components/atoms/Grid'
import SwiperClass from 'swiper/types/swiper-class'
import ProductSliderArrow, { ArrowActions } from './productSliderArrow'
import { ArrowDirection } from '@/components/molecules/arrows'
import SwiperCore, { Autoplay, Lazy, Pagination } from 'swiper/core'
import useTimer from 'hooks/useTimer'
import { validFor } from '@/theme/validFor'

export enum SliderDirection {
  vertical,
  horizontal
}

export enum ArrowsPosition {
  Inside,
  Outside
}

type ProductsSliderProps = {
  slidesToShow?: number | string
  sliderDirection?: SliderDirection
  spaceBetween?: number
  showPagination?: boolean
  autoPlay?: boolean | SliderAutoPlay
  infinite?: boolean
  arrowsTopPercent?: number
  arrowsBottomMargin?: number
  slidesPerGroup?: number | { desktop: number; tablet: number; mobile: number }
  breakpoints?: SliderBreakpoints
  arrowsPosition?: ArrowsPosition
  slideHeightPercent?: number
  showArrows?: boolean | 'auto'
  fillArrowBackground?: boolean
  pauseOnTouchMove?: boolean
  isProductDetailColorSliderInListing?: boolean
  isHomePageBanner?: boolean
} & Rest

type SliderBreakpoints = Record<
  number,
  {
    slidesPerView?: number | string
    slidesPerGroup?: number
  }
>

interface SliderAutoPlay {
  delay: number
  disableOnInteraction: boolean
  pauseOnMouseEnter: boolean
}

const ProductsSlider: FC<ProductsSliderProps> = ({
  slidesToShow = 'auto',
  slidesPerGroup = 1,
  sliderDirection = SliderDirection.horizontal,
  spaceBetween = 0,
  showPagination = false,
  autoPlay,
  arrowsTopPercent,
  arrowsBottomMargin,
  infinite = false,
  breakpoints,
  arrowsPosition = ArrowsPosition.Inside,
  slideHeightPercent,
  showArrows: initShowArrows = 'auto',
  fillArrowBackground = true,
  children,
  pauseOnTouchMove,
  isProductDetailColorSliderInListing = false,
  isHomePageBanner,
  ...rest
}) => {
  SwiperCore.use([Autoplay, Pagination, Lazy])
  const nextArrowRef = useRef<ArrowActions>(null)
  const prevArrowRef = useRef<ArrowActions>(null)
  const [swiper, setSwiper] = useState<SwiperClass>(null)
  const [showArrows] = useState(
    (initShowArrows === 'auto' &&
      typeof slidesToShow !== 'string' &&
      slidesToShow < React.Children.count(children)) ||
      initShowArrows
  )
  let autoplayTimeout

  const refreshSwiper = (swiper: SwiperClass) => {
    if (swiper) {
      swiper.update()
      onActiveIndexChange(swiper)
    }
  }

  useTimer(() => refreshSwiper(swiper), 200, [swiper])
  useTimer(() => refreshSwiper(swiper), 2000, [swiper])

  const pauseAndResumeAutoplay = () => {
    if (autoplayTimeout) {
      clearTimeout(autoplayTimeout)
    }

    if (swiper?.autoplay?.running) {
      swiper?.autoplay?.stop()
    }

    if (!swiper?.autoplay?.running) {
      autoplayTimeout = setTimeout(() => {
        swiper?.autoplay?.start()
      }, 30000)
    }
  }

  const isVertical = sliderDirection === SliderDirection.vertical
  const onClickPrev = () => {
    if (swiper.allowSlidePrev) {
      swiper.slidePrev()
      pauseOnTouchMove && pauseAndResumeAutoplay()
    }
  }
  const onClickNext = () => {
    if (swiper.allowSlideNext) {
      swiper.slideNext()
      pauseOnTouchMove && pauseAndResumeAutoplay()
    }
  }
  const onActiveIndexChange = (swiper: SwiperClass) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!swiper || !swiper.snapGrid) {
      return
    }
    if (!infinite) {
      const dimensionToCheck = isVertical ? swiper.height : swiper.width

      swiper.allowSlideNext =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dimensionToCheck + swiper.snapGrid[swiper.snapIndex] <
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        swiper.virtualSize
      swiper.allowSlidePrev = swiper.activeIndex > 0
    }

    if (nextArrowRef.current) {
      nextArrowRef.current.setEnabled(swiper.allowSlideNext)
    }
    if (prevArrowRef.current) {
      prevArrowRef.current.setEnabled(swiper.allowSlidePrev)
    }
  }
  return (
    <Flex
      flexDirection="column"
      position="relative"
      height="100%"
      {...rest}
      data-cy="productsSlider"
    >
      {showArrows && (
        <ProductSliderArrow
          isVertical={isVertical}
          onClick={onClickPrev}
          arrowDirection={ArrowDirection.prev}
          topPercent={arrowsTopPercent}
          bottomMargin={arrowsBottomMargin}
          fillBackground={fillArrowBackground}
          ref={prevArrowRef}
          data-cy="prevArrow"
          isHomePageBanner={isHomePageBanner}
        />
      )}
      <Box
        mx={arrowsPosition === ArrowsPosition.Outside ? 40 : 0}
        my={isVertical ? 10 : 0}
        flex="1 1 auto"
        position="relative"
      >
        <StyledBox setPositionAbsolute={isVertical}>
          <StyledSwiper
            data-cy="productSwiper"
            spaceBetween={spaceBetween}
            slidesPerGroup={slidesPerGroup}
            slidesPerView={slidesToShow}
            onActiveIndexChange={onActiveIndexChange}
            onTouchEnd={pauseOnTouchMove ? pauseAndResumeAutoplay : undefined}
            loop={infinite}
            autoplay={autoPlay}
            slideheight={slideHeightPercent}
            pagination={showPagination && { clickable: true }}
            onSwiper={(newSwiper) => {
              if (!showArrows) {
                return
              }
              setSwiper(newSwiper)
            }}
            direction={
              sliderDirection === SliderDirection.vertical
                ? 'vertical'
                : 'horizontal'
            }
            breakpoints={breakpoints}
            lazy
            isProductDetailColorSliderInListing={
              isProductDetailColorSliderInListing
            }
            {...rest}
          >
            {children}
          </StyledSwiper>
        </StyledBox>
      </Box>
      {showArrows && (
        <ProductSliderArrow
          isVertical={isVertical}
          onClick={onClickNext}
          arrowDirection={ArrowDirection.next}
          topPercent={arrowsTopPercent}
          bottomMargin={arrowsBottomMargin}
          fillBackground={fillArrowBackground}
          ref={nextArrowRef}
          data-cy="nextArrow"
          isHomePageBanner={isHomePageBanner}
        />
      )}
    </Flex>
  )
}

export default ProductsSlider

const StyledSwiper = styled(Swiper)`
  height: 100%;
  ${validFor.mobile`
    width: ${(props) => props.isProductDetailColorSliderInListing && '90%'};
    .picture-placeholder-wrapper {
      padding-bottom: 0px;
      background-color: transparent;
  }
  `}

  .swiper-pagination-bullet {
    background: ${(props) => props.theme.colors.background};
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: ${(props) => props.theme.colors.onBackground};
  }
  ${(props) =>
    props.slideheight &&
    css`
      .swiper-slide {
        height: ${props.slideheight}%;
      }
    `}
`

type StyledBoxProps = {
  setPositionAbsolute: boolean
}

const absoluteCss = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const StyledBox = styled<StyledBoxProps>(Box)`
  ${(props) => props.setPositionAbsolute && absoluteCss};
`
