import { forwardRef, useImperativeHandle, useState } from 'react'
import {
  ArrowDirection,
  HorizontalArrow,
  VerticalArrow
} from '@/components/molecules/arrows'
import styled from 'styled-components'

type ArrowsProps = {
  isVertical: boolean
  onClick: () => void
  topPercent?: number
  bottomMargin?: number
  arrowDirection: ArrowDirection
  fillBackground: boolean
  isHomePageBanner?: boolean
}

export type ArrowActions = {
  setEnabled: (isEnabled: boolean) => void
}

const ProductSliderArrow = forwardRef<ArrowActions, ArrowsProps>(
  (props, ref) => {
    const {
      isVertical,
      onClick,
      topPercent,
      bottomMargin,
      arrowDirection,
      fillBackground,
      isHomePageBanner
    } = props
    const [isEnabled, setIsEnabled] = useState<boolean>(false)

    const arrow = makeArrow(
      isVertical,
      arrowDirection,
      onClick,
      topPercent,
      bottomMargin,
      fillBackground,
      isHomePageBanner
    )
    useImperativeHandle(ref, () => ({
      setEnabled: (isEnabled) => {
        setIsEnabled(isEnabled)
      }
    }))

    return <>{arrow(isEnabled)} </>
  }
)

export default ProductSliderArrow

const makeArrow = (
  isVertical: boolean,
  arrowDirection: ArrowDirection,
  onClick: () => void,
  topPercent: number,
  bottomMargin: number,
  fillBackground: boolean,
  isHomePageBanner?: boolean
): ((enabled: boolean) => void) => {
  return isVertical
    ? (enabled) => {
        return (
          <VerticalArrow
            isDisabled={!enabled}
            arrowDirection={arrowDirection}
            onClick={onClick}
            data-cy="verticalArrows"
          />
        )
      }
    : (enabled) => (
        <StyledHorizontalArrow
          arrowDirection={arrowDirection}
          onClick={onClick}
          isDisabled={!enabled}
          topPercent={topPercent}
          bottomMargin={bottomMargin}
          fillBackground={fillBackground}
          data-cy="horizontalArrows"
          isHomePageBanner={isHomePageBanner}
        />
      )
}

type StyledArrowDirectionProps = {
  arrowDirection: ArrowDirection
  topPercent?: number
  isDisabled: boolean
  isHomePageBanner?: boolean
}

const StyledHorizontalArrow = styled<StyledArrowDirectionProps>(
  HorizontalArrow
)`
  position: absolute;
  ${(props) => props.isDisabled && 'display: none;'};
  top: ${(props) => (props.topPercent ? `${props.topPercent}%` : '50%')};
  margin-top: ${(props) => -36 - (props.bottomMargin ?? 0) / 2}px;
  ${(props) =>
    props.arrowDirection === ArrowDirection.prev ? 'left' : 'right'}: 0;
  width: 43px;
  height: 72px;
  z-index: 8;
  &:hover {
    ${(props) => !props.isDisabled && 'opacity: 0.5;'};
  }
  ${(props) => props.isDisabled && 'opacity: 0.2;'};
`
